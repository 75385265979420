/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";

import { faCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { useCookies } from "react-cookie";

import CustomModal from "@/shared/components/CustomModal";

function CookieSettings() {
    const [cookies, setCookie] = useCookies(["UpdatedUserCookieSettings"]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDefaultCookieOpen, setDefaultModalOpen] = useState(false);
    const [isEsentialChecked, setEsentialCheckBox] = useState(true);
    const [isAnalyticsChecked, setAnalyticsCheckBox] = useState(true);
    const [isAdvChecked, setAdvCheckBox] = useState(true);

    // console.log("cookies", cookies);

    const toggleCookieModal = () => {
        setUserCookie();
        //setIsModalOpen((prevState) => !prevState);
        setIsModalOpen(false);
        setDefaultModalOpen(false);
    };

    const openCookieModal = () => {
        setIsModalOpen(true);
    };

    const setUserCookie = () => {
        const cookieVal = {
            userConfig: {
                IsEsentialChecked: isEsentialChecked,
                IsAnalyticsChecked: isAnalyticsChecked,
                IsAdvChecked: isAdvChecked,
            },
        };

        const currentDate = new Date();
        const numberOfDaysToAdd = 400;
        const cookieExpiry = new Date(currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd));

        setCookie("UpdatedUserCookieSettings", cookieVal, { expires: cookieExpiry });

        setGoogleConsent(cookieVal);
    };

    const setGoogleConsent = (UpdatedUserCookieSettings: any) => {
        // console.log("UpdatedUserCookieSettings: ", UpdatedUserCookieSettings);

        let isCookieSet = false;

        if (UpdatedUserCookieSettings) {
            isCookieSet = true;

            if (UpdatedUserCookieSettings.userConfig.IsAnalyticsChecked && UpdatedUserCookieSettings.userConfig.IsAdvChecked) {
                // console.log(`updating consent, ${JSON.stringify(UpdatedUserCookieSettings.userConfig)}`);
                gtag("consent", "update", {
                    "ad_storage": "granted",
                    "analytics_storage": "granted",
                });
            } else if (UpdatedUserCookieSettings.userConfig.IsAnalyticsChecked && !UpdatedUserCookieSettings.userConfig.IsAdvChecked) {
                // console.log(`updating consent, ${JSON.stringify(UpdatedUserCookieSettings.userConfig)}`);
                gtag("consent", "update", {
                    "ad_storage": "denied",
                    "analytics_storage": "granted",
                });
            } else if (!UpdatedUserCookieSettings.userConfig.IsAnalyticsChecked && UpdatedUserCookieSettings.userConfig.IsAdvChecked) {
                // console.log(`updating consent, ${JSON.stringify(UpdatedUserCookieSettings.userConfig)}`);
                gtag("consent", "update", {
                    "ad_storage": "granted",
                    "analytics_storage": "denied",
                });
            } else {
                // console.log(`updating consent, ${JSON.stringify(UpdatedUserCookieSettings?.userConfig)}`);
                gtag("consent", "update", {
                    "ad_storage": "denied",
                    "analytics_storage": "denied",
                });
            }
        } else {
            // console.log("updating consent to default");
            gtag("consent", "default", {
                "ad_storage": "granted",
                "analytics_storage": "granted",
            });
        }

        setDefaultModalOpen(!isCookieSet);
    };

    const setCheckboxValues = (evt: any, typeId: number) => {
        if (typeId == 1) {
            setEsentialCheckBox(evt.currentTarget.checked);
        } else if (typeId == 2) {
            setAnalyticsCheckBox(evt.currentTarget.checked);
        } else if (typeId == 3) {
            setAdvCheckBox(evt.currentTarget.checked);
        }
        return evt.currentTarget.checked;
    };

    useEffect(() => {
        setGoogleConsent(cookies.UpdatedUserCookieSettings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {/* HTML FOR SHARE MODAL */}
            <CustomModal
                open={isModalOpen}
                handleClose={toggleCookieModal}
                className={"custom-modal-content"}>
                <div className={"commonModalWrapper"}>
                    <div className="commonModal cookiesModal">
                        <div className="modalHeader">
                            <button className="unstyledButton headerClose" onClick={toggleCookieModal}>
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                        <h5>Your cookie settings</h5>

                        <div className="modalBody">
                            <p className="body-default-copy-medium">Cookies are very small text files that are stored on your computer when you visit some websites.
                                We use cookies to make our website easier for you to use. You can remove any cookies already stored on your computer,
                                but these may prevent you from using parts of our website.</p>
                            <button className="btn-green acceptAllBtn" onClick={toggleCookieModal}>Accept all</button>
                            <div className="cookiesControls">
                                <div className="controlList">
                                    <div className="leftPane">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <div>
                                            <h6>Essential</h6>
                                            <p className="body-default-copy-small">Collect anonymous data about how visitors use our site
                                                and how it performs. We use this to improve our products, services and user experience.</p>
                                        </div>
                                    </div>
                                    {/* <button className="btn-blue" onClick={setActiveValues}>Always active</button> */}
                                    <div className="switchItem">
                                        <label className="switch">
                                            <input type="checkbox" checked={isEsentialChecked} disabled={true} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="controlList">
                                    <div className="leftPane">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <div>
                                            <h6>Analytics</h6>
                                            <p>Analytics cookies allow TGI to analyse your visits and actions on our and third-party apps and websites to understand your interests.</p>
                                        </div>

                                    </div>
                                    <div className="switchItem">
                                        <label className="switch">
                                            <input type="checkbox" checked={isAnalyticsChecked} onClick={(evt: any) => setCheckboxValues(evt, 2)} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="controlList">
                                    <div className="leftPane">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <div>
                                            <h6>Advertising</h6>
                                            <p>Targeted advertising cookies allows TGI to share your data with advertising partners, including social
                                                media companies, to send you more relevant ads.</p>
                                        </div>

                                    </div>
                                    <div className="switchItem">
                                        <label className="switch">
                                            <input type="checkbox" checked={isAdvChecked} onClick={(evt: any) => setCheckboxValues(evt, 3)} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button className="btn-pink confirmBtn" onClick={toggleCookieModal}>Confirm choices</button>
                        </div>
                    </div>
                </div >
            </CustomModal>
            <div className={(isDefaultCookieOpen && !isModalOpen) ? "cookiesBarModal show" : "cookiesBarModal hide"}>
                <div className="content">
                    <p
                        style={{
                            width: "100%",
                            maxWidth: "100%",
                        }}>We use cookies to effortlessly improve your experience on our site. Cookies also help us understand how our site is being used, so that we can keep making it better.
                        You can read more about it in our <Link href="/cookies" className="linkText">cookie policy</Link> and <span
                            className="linkText" onClick={openCookieModal}>change your settings</span> here at any time.</p>
                    <button className="btn-green" onClick={toggleCookieModal}>Accept All</button>
                </div>
            </div>
        </div>
    );
}

export default CookieSettings;
